<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      :rules="rules"
      label-width="100px"
      style="width:100%;"
    >
      <el-divider content-position="left">问题基本信息</el-divider>
      <el-form-item label="问题" prop="question" required>
        <el-input v-model="form.question" placeholder="请输入问题"></el-input>
      </el-form-item>
      <el-form-item label="答案" prop="answer" required>
        <el-input v-model="form.answer" placeholder="请输入问题"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="addData('form')" :plain="true">立即添加</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>

  let _this;
  let id = 0;
  import {getTypeSelect,addData} from "@/api/question.js";

  export default {
    props: {
      row: Object,
      editId: Number
    },
    data() {
      return {
        typeOptions: [],
        form: {
          question: "",
          answer: "",
        },
        show: true,
        placeholder: "",
        id: "",
        rules: {
          "question": [
            {required: true, message: "请输入问题", trigger: "blur"},
          ],
          "answer": [
            {required: true, message: "请输入答案", trigger: "blur"},
          ],
        },
        innerVisible:false
      };
    },

    computed: {},

    mounted() {
      _this = this;
      _this.init();

    },

    methods: {
      // 初始化数据
      init() {
      },

      close() {
        _this.$emit("close");
      },

      addData(form) {
        this.$refs[form].validate(valid => {
          if (valid) {
            addData( _this.form).then((res) => {
              if (res.code === 200) {
                _this.$message({
                  message: "添加成功",
                  type: "success"
                });
                setTimeout(() => {
                  _this.$emit("success");
                }, 1000);
              }
            });
            this.form = form
          } else {
            console.log("error submit!!");
            return false;
          }
        })
      },
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }
</style>
