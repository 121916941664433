<template>
  <section>
    <div class="container">
      <el-row :gutter="20" :span="24">
        <el-col :span="6">
          <div class="grid-content">
            <div class="title">
              商户数
            </div>
            <div class="number">{{total_data.store.total}}家</div>
            <div class="week">
              <span>周同比</span>
              <span style="margin-left:10px;">
                {{total_data.store.week_compare}}%
                <i v-if="total_data.store.week_compare>=0" class="el-icon-sort-up" style="color:red;font-weight: bolder"></i>
                <i v-else class="el-icon-sort-down" style="color:greenyellow;font-weight: bolder"></i>
              </span>
            </div>
            <div class="daily">
              <span>日同比</span>
              <span style="margin-left:10px;">
                {{total_data.store.day_compare}}%
                <i v-if="total_data.store.day_compare>=0" class="el-icon-sort-up" style="color:red;font-weight: bolder"></i>
                <i v-else class="el-icon-sort-down" style="color:greenyellow;font-weight: bolder"></i>
              </span>
            </div>
            <el-divider></el-divider>
            <div class="day">
              <span>日增长</span>
              <span style="margin-left:10px;">{{total_data.store.day}}家</span>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content">
            <div class="title">
              用户数
            </div>
            <div class="number">{{total_data.user.total}}人</div>
            <div class="week">
              <span>周同比</span>
              <span style="margin-left:10px;">
                {{total_data.user.week_compare}}%
                <i v-if="total_data.user.week_compare>=0" class="el-icon-sort-up" style="color:red;font-weight: bolder"></i>
                <i v-else class="el-icon-sort-down" style="color:greenyellow;font-weight: bolder"></i>
              </span>
            </div>
            <div class="daily">
              <span>日同比</span>
              <span style="margin-left:10px;">
                {{total_data.user.day_compare}}%
                <i v-if="total_data.user.day_compare>=0" class="el-icon-sort-up" style="color:red;font-weight: bolder"></i>
                <i v-else class="el-icon-sort-down" style="color:greenyellow;font-weight: bolder"></i>
              </span>
            </div>
            <el-divider></el-divider>
            <div class="day">
              <span>日增长</span>
              <span style="margin-left:10px;">{{total_data.user.day}}人</span>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content">
            <div class="title">
              订单数
            </div>
            <div class="number">{{total_data.order_count.total}}单</div>
            <div class="week">
              <span>周同比</span>
              <span style="margin-left:10px;">
                {{total_data.order_count.week_compare}}%
                <i v-if="total_data.order_count.week_compare>=0" class="el-icon-sort-up" style="color:red;font-weight: bolder"></i>
                <i v-else class="el-icon-sort-down" style="color:greenyellow;font-weight: bolder"></i>
              </span>
            </div>
            <div class="daily">
              <span>日同比</span>
              <span style="margin-left:10px;">
                {{total_data.order_count.day_compare}}%
                <i v-if="total_data.order_count.day_compare>=0" class="el-icon-sort-up" style="color:red;font-weight: bolder"></i>
                <i v-else class="el-icon-sort-down" style="color:greenyellow;font-weight: bolder"></i>
              </span>
            </div>
            <el-divider></el-divider>
            <div class="day">
              <span>日增长</span>
              <span style="margin-left:10px;">{{total_data.order_count.day}}单</span>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content">
            <div class="title">
              订单总额
            </div>
            <div class="number">￥{{total_data.order_amount.total}}</div>
            <div class="week">
              <span>周同比</span>
              <span style="margin-left:10px;">
                {{total_data.order_amount.week_compare}}%
                <i v-if="total_data.order_amount.week_compare>=0" class="el-icon-sort-up" style="color:red;font-weight: bolder"></i>
                <i v-else class="el-icon-sort-down" style="color:greenyellow;font-weight: bolder"></i>
              </span>
            </div>
            <div class="daily">
              <span>日同比</span>
              <span style="margin-left:10px;">
                {{total_data.order_amount.day_compare}}%
                <i v-if="total_data.order_amount.day_compare>=0" class="el-icon-sort-up" style="color:red;font-weight: bolder"></i>
                <i v-else class="el-icon-sort-down" style="color:greenyellow;font-weight: bolder"></i>
              </span>
            </div>
            <el-divider></el-divider>
            <div class="day">
              <span>日增长</span>
              <span style="margin-left:10px;">￥{{total_data.order_amount.day}}</span>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" :span="24">
        <el-col :span="12">
          <div class="grid-content" id="mapOption" style="height:600px;width:100%;background-color: white;" ></div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content" id="ageOption" style="height:600px;width:100%;background-color: white;" ></div>
        </el-col>
        
      </el-row>
      <el-row :gutter="20" :span="24">
        <el-col :span="12">
          <div class="grid-content" id="goodsOption" style="height:600px;width:100%;background-color: white;" ></div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content" id="genderOption" style="height:600px;width:100%;background-color: white;" ></div>
        </el-col>
      </el-row>
    </div>
  </section>
</template>

<script>
  let _this
  const colors = ['#5470C6', '#91CC75', '#EE6666'];
  import {index} from "@/api/public"
  export default {
    name: "App",
    data() {
      return {
        total_data:{
          "store":{"total":0,"day":0,"week_compare":0,"day_compare":0},
          "user":{"total":0,"day":0,"week_compare":0,"day_compare":0},
          "order_count":{"total":0,"day":0,"week_compare":0,"day_compare":0},
          "order_amount":{"total":"0.00","day":"0.00","week_compare":0,"day_compare":0}
        },
        order_statistic:{
          title:{
            text:"年度订单报表"
          },
          color: colors,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            }
          },
          grid: {
            right: '20%'
          },
          toolbox: {
            
          },
          legend: {
            data: ['订单数', '销售额']
          },
          xAxis: [
            {
              type: 'category',
              axisTick: {
                alignWithLabel: true
              },
              data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '订单数',
              position: 'right',
              alignTicks: true,
              axisLine: {
                show: true,
                lineStyle: {
                  color: colors[0]
                }
              },
              axisLabel: {
                formatter: '{value} 单'
              }
            },
            {
              type: 'value',
              name: '销售额',
              position: 'left',
              alignTicks: true,
              axisLine: {
                show: true,
                lineStyle: {
                  color: colors[1]
                }
              },
              axisLabel: {
                formatter: '￥{value}'
              }
            }
          ],
          series: [
            {
              name: '订单数',
              type: 'bar',
              data: [
                2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
              ]
            },
            {
              name: '销售额',
              type: 'line',
              yAxisIndex: 1,
              data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
            }
          ]
        },
        age_statistic:{
          title: {
            text: '年龄分布',
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            left: 'right'
          },
          series: [
            {
              name: 'Access From',
              type: 'pie',
              radius: '50%',
              data: [
                { value: 1048, name: '20岁前' },
                { value: 735, name: '20-30岁' },
                { value: 484, name: '30-40岁' },
                { value: 861, name: '40-50岁' },
                { value: 745, name: '50-60岁' },
                { value: 1058, name: '60岁以上' },
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
        goods_statistic:{
          title:{
            text:"商品销售额"
          },
          color: colors,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            }
          },
          grid: {
            right: '20%'
          },
          toolbox: {
            
          },
          legend: {
            data: ['订单数', '销售额']
          },
          xAxis: [
            {
              type: 'category',
              axisTick: {
                alignWithLabel: true
              },
              data: ['御守香囊福平安符好运护身符手机车包挂件中式香包', '御守香囊寿平安符好运招财护身符手机车包挂件中式香包', '御守香囊喜平安符好运招财护身符手机车包挂件中式香包', '平安好运御守有求必应护身符招财好运健康平安符手机包挂件香囊', '完美婚姻御守有求必应护身符招财好运健康平安符手机包挂件香囊', '出入平安观音御守护身符招财好运健康平安符手机包挂件香囊', '消灾观音御守护身符招财好运健康平安符手机包挂件香囊', '出入平安观音护身符招财好运健康平安符手机包挂件香囊']
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '订单数',
              position: 'right',
              alignTicks: true,
              axisLine: {
                show: true,
                lineStyle: {
                  color: colors[0]
                }
              },
              axisLabel: {
                formatter: '{value} 单'
              }
            },
            {
              type: 'value',
              name: '销售额',
              position: 'left',
              alignTicks: true,
              axisLine: {
                show: true,
                lineStyle: {
                  color: colors[1]
                }
              },
              axisLabel: {
                formatter: '￥{value}'
              }
            }
          ],
          series: [
            {
              name: '订单数',
              type: 'bar',
              data: [
                2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2
              ]
            },
            {
              name: '销售额',
              type: 'line',
              yAxisIndex: 1,
              data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4]
            }
          ]
        },
        gender_statistic:{
          title: {
            text: '性别分布',
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            left: 'right'
          },
          series: [
            {
              name: 'Access From',
              type: 'pie',
              radius: '50%',
              data: [
                { value: 1048, name: '男性' },
                { value: 735, name: '女性' },
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
      }
    },
    methods: {
      // 获取订单列表
      mapEchartsInit () {
        _this.$echarts.init(document.getElementById('mapOption')).setOption(_this.order_statistic);
        _this.$echarts.init(document.getElementById('ageOption')).setOption(_this.age_statistic);
        _this.$echarts.init(document.getElementById('goodsOption')).setOption(_this.goods_statistic);
        _this.$echarts.init(document.getElementById('genderOption')).setOption(_this.gender_statistic);
      }

    },
    mounted() {
      _this = this
      // this.$nextTick(() => {
      
      // });
      index().then(res=>{
        if(res.code === 200){
          this.total_data = res.data
          console.log(res.data)
        }
      })
      this.mapEchartsInit()
      
    },
  };
</script>

<style scoped lang="scss">
  @import "@/static/default";

  .temple {
    width: 100%;
    background-color: white;
    padding: 10px 50px;
    // padding-left: 50px;
  }

  .grid-content{
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    .title{
      width: 100%;
      font-size: 14px;
      color:gray;
    }
    .number{
      margin-top: 10px;
      width: 100%;
      font-size: 24px;
      font-weight: bold;
    }

    .week{
      padding: 5px 0;
      span{
        font-size: 14px;

      }
    }
    .daily{
      padding: 5px 0;
      span{
        font-size: 14px;

      }
    }
    .day{
      span{
        font-size: 16px;
      }
    }
  }

</style>
<style>
  .el-row {
    margin-bottom: 20px;
    width: 100%;
  &
  :last-child {
    margin-bottom: 0;
  }

  }
  .el-col {
    border-radius: 4px;
  }
</style>
