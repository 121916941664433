
    import {Component, Prop, Vue, Emit, Watch} from 'vue-property-decorator';

    const components_links = require("./package.json")

    @Component
    export default class ToolsBox extends Vue {
        @Prop({default: []}) config!: string[];
        @Prop({default: true}) showAdd!: boolean;
        @Prop({default: false}) showBack!: boolean;
        private componentsArr:Array<any>[] = [];
        private data:any = {}
        private clear:boolean=false;
        @Emit('openAdd')
         handleAdd() {console.log(1);}
        @Emit('handleSearch')
         search(){}
        @Emit('backAll')
         back(){ this.clear=true }
        mounted() {
          this.config.forEach((value,key)=>{
              if(components_links[value]){
                  let comp = () => import(`${components_links[value]}`)
                  let ref = `${value}Ref`
                  this.componentsArr.push([comp,ref])
                  this.data[ref] = {}
              }
          })
        }
        private handleChange(e: any){

        }
        private handleClick(e: any){

        }
        private handleMessage(e: any){

        }
        //  public getName(name):void{
        //    console.log(name);
        //  }
    }
