<template>
  <section>
    <div class="container">
      <div class="temple">
        <tools-box :config="toolsConfig" @getOrderType="getOrderType" @getOrderNum="getOrderNum" @handleSearch='getTable' :show="false"></tools-box>
      </div>
      <!--订单列表-->
      <el-table :data="table" class="table">

        <!-- 数据展示区 -->
        <el-table-column
          label="订单号"
          prop="serial"
          align="left"
          width="180"
        ></el-table-column>
        <el-table-column
          label="产品列表"
          prop="serial"
          align="left"
          width="280"
        >
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <div style="width:400px;">
                <div style="display:inline-flex;align-items: center;width:100%; border-bottom: 1px solid rgba(0,0,0,0.2);padding-bottom:10px;margin-top:10px;" v-for="(value,key) in scope.row.order_goods">
                  <div style="width: 100px;height:100px;overflow: hidden;"><el-image style="width:100px;heigth:100px;" fit="cover" :src="value.goods_images.length>0 ? value.goods_images[0] : '' "></el-image></div>
                  <div style="margin-left:20px;">{{ value.goods_name }}</div>
                  <div style="margin-left:20px;">数量：{{ value.goods_quantity }}</div>
                </div>
              </div>
              <el-button size="mini" slot="reference">{{scope.row.order_goods[0].goods_name}}等，查看详情</el-button>
            </el-popover>
          </template>
        </el-table-column>
        
        <el-table-column label="用户" prop="realname" align="left" width="140">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <p>ID: {{ scope.row.user.id }}</p>
              <p>姓名: {{ scope.row.user.nickname }}</p>
              <p>手机号: {{ scope.row.user.phone }}</p>
              <div slot="reference" class="name-wrapper">
                <el-tag>{{scope.row.user.phone}}</el-tag>
              </div>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column
          label="付款金额"
          prop="amount"
          align="left"
          width="100"
        ></el-table-column>
        <el-table-column
          label="快递单号"
          prop="express_no"
          align="left"
          width="180"
        ></el-table-column>
        <el-table-column
          label="运费"
          prop="express_price"
          align="left"
          width="100"
        ></el-table-column>
        <el-table-column
          label="订单状态"
          prop="is_other"
          align="left"
          width="140"
        >
          <template slot-scope="scope">
            <el-tag size="mini">{{ scope.row.status_zh }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="收货人姓名"
          prop="is_other"
          align="left"
          width="140"
        >
          <template slot-scope="scope">
            <el-tag size="mini">{{ scope.row.user_name }}</el-tag>
          </template>
        </el-table-column>
        
        <el-table-column
          label="发货省市区"
          prop="province_name"
          align="left"
          width="200"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.province_name }} {{ scope.row.city_name }} {{ scope.row.area_name }}</span>
          </template>
        </el-table-column>

        <el-table-column
          label="详细地址"
          prop="address"
          align="left"
          width="240"
        ></el-table-column>

        <el-table-column
          label="订单时间"
          prop="created_at"
          align="left"
          width="155"
        ></el-table-column>

        <el-table-column label="操作" align="right" fixed="right">
            <template slot-scope="scope">
              <div
                style="
                  width: 100%;
                  display: inline-flex;
                  justify-content: flex-end;
                "
              >
                <!-- <el-link style="color: #409eff;" v-if="(scope.row.status !==2 || scope.row.status !==3)" @click="handleDetail(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="查看詳情" placement="top-start">
                    <i class="el-icon-view" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>-->
                <el-link style="color: #409eff;margin-right:20px" @click="express(scope.row)">
                  <el-tooltip class="item" effect="dark" content="快递单号" placement="top-start">
                    <i class="el-icon-edit" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link> 
                <el-dropdown @command="command($event,scope.row)">
                  <span class="el-dropdown-link" style="color: #409eff">
                    <el-tooltip class="item" effect="dark" content="更多操作" placement="top-start">
                      <i class="el-icon-arrow-down" style="font-size:16px;"></i>
                    </el-tooltip>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="express">快递单号</el-dropdown-item>

                    <el-dropdown-item command="cancel" divided>取消订单</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
      </el-table>

      <!--分页-->
      <el-col :span="24" class="toolbar">
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @prev-click="prevpage"
          @next-click="nextpage"
          :page-size="10"
          :total="total"
          style="float: right"
        ></el-pagination>
      </el-col>
    </div>

    <el-dialog
      title="修改快递单号"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div style="margin-top:20px;">快递号</div>
      <el-input v-model="express_no" placeholder="请输入快递单号" style="width:300px;margin-top:10px;"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
// import { getCascader } from "@/api/provider.js";
import { getTable, updateExpress,updateStatus} from "@/api/order.js";
import ToolsBox from "@/components/tool/Index.vue";

export default {
  name: "App",
  components: { ToolsBox },
  data() {
    return {
      toolsConfig: ["searchOrderType", "searchOrderNum"],
      outerVisible:false,
      params: {
        page:1,
        order_number:'',
        status:''
      },

      dialogVisible:false,
      express_no:'',
      express_id:'',

      formPrice:{id:0,price:''},
      priceVisible:false,
      statusVisible:false,
      formStatus:{id:0,status:''},
      cascaderOpthion:[],
      cascaderValue:'',
      table: [],
      total: 0,
      showBack: false,
      appointID:0,

      orderType:[
        {
          value: 0,
          label: "初始化",
        },
        {
          value: 1,
          label: "接单/派单",
        },
        {
          value: 2,
          label: "执行中",
        },
        {
          value: 3,
          label: "付款",
        },
        {
          value: 4,
          label: "结束",
        },
        {
          value: 9,
          label: "取消",
        }
      ]
    };
  },
  methods: {
    // 获取订单列表
    getTable() {
      console.log(1341234)
      getTable(this.params).then((res) => {
        if (res.code === 200) {
          console.log(res.data)
          this.table = res.data.data
          this.total = res.data.total;
        }
      });
    },

    cancel(){
      this.dialogVisible=false
    },
    confirm(){
      let _this = this
      if(this.express_no==='' ){
        return _this.$confirm('请输入快递号')
      }
      
      updateExpress(this.express_id,{'express_no':this.express_no}).then((res) => {
        if (res.code === 200) {
          this.dialogVisible=false      
          _this.$message({
            message: "修改成功",
            type: "success"
          });
          this.express_id = 0
          this.express_no = ''
          _this.getTable()
        }
      });
    },

    express(row){
      this.express_id = row.id
      this.express_no = row.express_no
      this.dialogVisible = true
    },

    command(e,row){
      
      if(e === 'express'){
        this.express_id = row.id
        this.express_no = row.express_no
        this.dialogVisible = true
      }

      if(e === 'cancel'){
        this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          updateStatus(row.id,{status:9}).then(res =>{
            if (res.code===200){
              this.getTable();
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }
          })
        }).catch(() => {
        });
      }
    },
    changePrice(){
      editData(this.formPrice.id,{'price':this.formPrice.price}).then(res=>{
        if (res.code === 200){
          this.getTable()
          this.$message({
            type: 'success',
            message: '更改成功!'
          });
          this.priceVisible = false
        }
      })
    },
    changeStatus(){
      editData(this.formStatus.id,{'status':this.formStatus.status}).then(res=>{
        if (res.code === 200){
          this.getTable()
          this.$message({
            type: 'success',
            message: '更改成功!'
          });
          this.statusVisible = false
        }
      })
    },
    // 查询
    getOrderType(type) {
      console.log(type)
      this.params.status = type;
    },
    getOrderNum(number) {
      this.params.order_number = number;
    },
    handleBack() {
      this.showBack = false;
      this.params = {};
      this.getTable();
    },

    // 删除订单
    handleDelete(index, row) {
      this.$confirm("确认删除？")
        .then((_) => {
          deleteOrder(row.id, { deleted: 1 })
            .then((data) => {
              if (data.code === 200) {
                this.getOrderInfo();
              }
            })
            .catch((error) => {});
        })
        .catch((_) => {});
    },

    // 分页
    prevpage() {
      this.params.page -= 1;
      this.getTable()
    },
    nextpage() {
      this.params.page += 1;
      this.getTable()
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.getTable()
    },

    goAppoint(){
      let company = this.cascaderValue[0] ? 0 : this.cascaderValue[0]
      goAppoint(this.appointID,{company_id:company, provider:this.cascaderValue[1]}).then(res=>{
        if (res.code === 200){
          this.$message({
            type: 'success',
            message: '指派成功!'
          });
          this.outerVisible = false
          this.getTable()
        }
      })
    }
  },
  mounted() {
    this.getTable();
  },
};
</script>

<style scoped lang="scss">
@import "@/static/default";
.temple {
  width: 100%;
  background-color: white;
  padding: 10px 50px;
  // padding-left: 50px;
}
.order {
  width: 100%;
  background-color: white;
  padding: 10px 50px;
  // padding-left: 50px;
}
.container {
  background-color: #fff !important;
  padding: 0 32px;
  .table {
    margin-top: 24px !important;
  }
  .toolbar {
    background: #fff;
    width: calc(100% + 64px);
    padding: 16px 32px 26px 0;
    margin-left: -32px;
  }
}

.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
