<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      label-width="100px"
      style="width:100%;"
    >
      <el-form-item label="灵签名称:" prop="title" >
        <el-input v-model="form.title" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="灵签概述:" prop="desc_one"  >
        <!--<el-input v-model="form.desc_one" placeholder=""></el-input>-->
        <!-- 富文本编辑器组件 -->
        <quill-editor v-model="form.desc_one" ref="myQuillEditor"></quill-editor>
      </el-form-item>

      <el-form-item label="灵签图片地址:" prop="image" >
        <el-input v-model="form.image" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="解签:" prop="desc_tow">
        <!-- <el-input v-model="form.desc_tow" placeholder=""></el-input>-->
        <!-- 富文本编辑器组件 -->
        <quill-editor v-model="form.desc_tow" ref="myQuillEditor"></quill-editor>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="submit('form')" :plain="true">立即修改</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
  let _this;
  import { editData,getQiuqianById,updateQiuqian } from "@/api/system.js";
  import {
    quillEditor
  } from 'vue-quill-editor'
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'
  import UploadQiniu from "@/components/tool/lib/upload/index.vue";
  export default {
    components: {
     quillEditor,
     UploadQiniu
    },
    props: {
      id: Number
    },
    data() {
      return {
        form: {
          title: '',
          desc_one: '',
          image: '',
          desc_tow: '',
        },        
      };
    },

    computed: {},

    mounted() {
      _this = this;
      _this.init();
     
    },

    methods: {
      // 初始化数据
      init() {
        getQiuqianById(this.id).then(res=>{
          console.log('1234'
          )
          if(res.code === 200){
            console.log(this.form)
            this.form = res.data
            
          }
        })
      },
      
      close() {
        _this.$emit("close");
      },
      submit(form) {
        this.$refs[form].validate(valid => {
          if (valid) {
            updateQiuqian(this.id,_this.form).then(res => {
              if (res.code === 200) {
                _this.$message({
                  message: "修改成功",
                  type: "success"
                });
                setTimeout(() => {
                  _this.$emit("success");
                }, 1000);
              }
            })
          } else {
            console.log("error submit!!");
            return false;
          }
        })
      }
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width:500;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }
</style>
