<template>
  <section>
    <div class="container">
      <div class="temple">
      <!--  <tools-box :config="toolsConfig" @getOrderType="getOrderType" @getOrderNum="getOrderNum" @handleSearch='getTable' :show="false"></tools-box>-->
      </div>
      <!--订单列表-->
      <el-table :data="table" class="table">

        <!-- 数据展示区 -->
        <el-table-column
          label="签-名"
          prop="title"
          align="left"
          width="150"
        ></el-table-column>
        <el-table-column
          label="概述"
          prop="desc_one"
          align="center"
          width="150"
        >
       <template slot-scope="scope">
              <el-popover placement="top-start"
                          trigger="hover"
                          :content="scope.row.desc_one">
                  <div slot="reference" class="oneLineCls">{{scope.row.desc_one}}</div>
              </el-popover>
            </template>
        </el-table-column>

              <el-table-column
          label="图片地址"
          prop="image"
          align="center"
          width="300"
        ></el-table-column>

        <el-table-column
          label="吉凶解签"
          prop="desc_tow"
          align="center"
          width="300"
        >
        <template slot-scope="scope">
              <el-popover placement="top-start"
                          trigger="hover"
                          :content="scope.row.desc_tow">
                  <div slot="reference" class="oneLineCls">{{scope.row.desc_tow}}</div>
              </el-popover>
            </template>
        </el-table-column>

        <el-table-column label="操作" align="right" fixed="right">
            <template slot-scope="scope">
              <div
                style="
                  width: 100%;
                  display: inline-flex;
                  justify-content: flex-end;
                "
              >
                <!-- <el-link style="color: #409eff;" @click="handleDetail(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="查看詳情" placement="top-start">
                    <i class="el-icon-view" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>-->

                
              <el-link style="color: #409eff;" @click="handleEdit(scope.row.id)">
                <el-tooltip class="item" effect="dark" content="修改" placement="top-start">
                  <i class="el-icon-edit" style="font-size:16px;"></i>
                </el-tooltip>
              </el-link>
              <el-link style="color: #409eff;" @click="handleDelete(scope.row.id)" v-if="scope.row.menu_id !== 'system_management'">
                <el-tooltip class="item" effect="dark" content="刪除" placement="top-start">
                <i class="el-icon-delete" style="font-size:16px;"></i>
                </el-tooltip>
              </el-link>
   
               <!-- <el-dropdown @command="command($event,scope.row)">
                  <span class="el-dropdown-link" style="color: #409eff">
                    <el-tooltip class="item" effect="dark" content="更多操作" placement="top-start">
                      <i class="el-icon-arrow-down" style="font-size:16px;"></i>
                    </el-tooltip>
                  </span>
                </el-dropdown>-->
              </div>
            </template>
          </el-table-column>
      </el-table>

    <el-drawer
      title="修改灵签"
      :visible.sync="editDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <edit-comp v-if="editDrawer" @close="handleClose" @success="handleDrawerSuccess" :id="editID" ref="newForm"></edit-comp>
    </el-drawer>

      <!--分页-->
      <el-col :span="24" class="toolbar">
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @prev-click="prevpage"
          @next-click="nextpage"
          :page-size="10"
          :total="total"
          style="float: right"
        ></el-pagination>
      </el-col>
    </div>
  </section>
</template>

<script>
// import { getCascader } from "@/api/provider.js";
import { getQiuqian,deleteQq } from "@/api/system.js";
import ToolsBox from "@/components/tool/Index.vue";
import editComp from "./components/edit.vue";
export default {
  name: "App",
  components: { ToolsBox ,editComp},
  data() {
    return {
      //toolsConfig: ["searchOrderType", "searchOrderNum"],
      //outerVisible:false,
      params: {
        page:1,
      },
       
      //cascaderOpthion:[],
      //cascaderValue:'',
      editDrawer: false,
      editID: '',
      table: [],
      total: 0,
      showBack: false,
      appointID:0,
      visible: false,
      direction: "rtl",
    };
  },
  methods: {
    // 获取命签列表
    getTable() {
      console.log(1341234)
      getQiuqian(this.params).then((res) => {
        if (res.code === 200) {
          console.log(res.data)
          this.table = res.data.data
          this.total = res.data.total;
        }
      });
    },
    //删除
     handleDelete(id) {
        this.$confirm('删除后无法恢复，请慎重考虑，确认删除数据吗？')
          .then(_ => {
            deleteQq(id).then(res=>{
                this.getTable()
                this.$notify({
                  title: '成功',
                  message: '删除成功',
                  type: 'success'
                });
              })
          })
          .catch(_ => {});
      },
    // 查询

    handleBack() {
      this.showBack = false;
      this.params = {};
      this.getTable();
    },

     // 关闭添加/修改
      handleClose() {
        this.$confirm("确认关闭？")
          .then((_) => {
            this.editDrawer = false;
          })
          .catch((_) => {});
      },

      handleEdit(id){
        this.editID = id
        this.editDrawer = true
      },
      // 添加/修改成功
      handleDrawerSuccess() {
        this.editDrawer = false;
        this.getTable();
        this.$refs.newForm.$refs.form.resetFields();
      },
      // 清空子组件
      clearContent() {
        this.$refs.newForm.$refs.form.resetFields();
      },


    // 分页
    prevpage() {
      this.params.page -= 1;
      this.getTable()
    },
    nextpage() {
      this.params.page += 1;
      this.getTable()
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.getTable()
    },

    
  },
  mounted() {
    this.getTable();
  },
};
</script>

<style scoped lang="scss">
@import "@/static/default";
.temple {
  width: 100%;
  background-color: white;
  padding: 10px 50px;
  // padding-left: 50px;
}
.order {
  width: 100%;
  background-color: white;
  padding: 10px 50px;
  // padding-left: 50px;
}
.container {
  background-color: #fff !important;
  padding: 0 32px;
  .table {
    margin-top: 24px !important;
  }
  .toolbar {
    background: #fff;
    width: calc(100% + 64px);
    padding: 16px 32px 26px 0;
    margin-left: -32px;
  }
}

.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.oneLineCls{
	  text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
     text-overflow: ellipsis;
     display: -webkit-box;
     -webkit-line-clamp: 1;
     line-clamp: 1;
     -webkit-box-orient: vertical;
  }
</style>
