var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticStyle:{"padding":"0 5%"}},[_c('el-form',{ref:"form",staticStyle:{"width":"100%"},attrs:{"label-position":"top","status-icon":"","model":_vm.form,"rules":_vm.rules,"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"内容名称","prop":"name","required":""}},[_c('el-input',{attrs:{"placeholder":"内容名称,不可重复"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"类型","prop":"type","required":""}},[_c('el-radio-group',{attrs:{"size":"small"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},[_c('el-radio',{attrs:{"label":0,"border":""}},[_vm._v("海报")])],1)],1),_c('el-form-item',{attrs:{"label":"上传内容","prop":"src","required":""}},[_c('upload-qiniu',{attrs:{"path":"media","multiple":false,"images":_vm.imageUrl},on:{"success":_vm.handleContentSuccess,"remove":_vm.handleContentRemove}})],1),_c('el-form-item',{attrs:{"label":"显示区域","prop":"province_id"}},[_c('districts-cascader',{attrs:{"level":3,"showAll":true},on:{"choose":_vm.handleAreaDistrictsChoose}})],1),_c('el-form-item',{attrs:{"label":"显示时间范围","prop":"start_time"}},[_c('div',{staticStyle:{"display":"inline-flex","width":"100%"}},[_c('el-time-select',{attrs:{"placeholder":"起始时间","picker-options":{
            start: '00:00',
            step: '00:30',
            end: '23:30'
          }},model:{value:(_vm.form.start_time),callback:function ($$v) {_vm.$set(_vm.form, "start_time", $$v)},expression:"form.start_time"}}),_c('span',{staticStyle:{"margin-left":"5px","margin-right":"5px"}},[_vm._v("至")]),_c('el-time-select',{attrs:{"placeholder":"结束时间","picker-options":{
            start: '00:00',
            step: '00:30',
            end: '23:59',
            minTime: _vm.form.start_time
          }},model:{value:(_vm.form.end_time),callback:function ($$v) {_vm.$set(_vm.form, "end_time", $$v)},expression:"form.end_time"}})],1)]),_c('el-form-item',[_c('el-button',{on:{"click":function($event){return _vm.close('form')}}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary","plain":true},on:{"click":function($event){return _vm.submit('form')}}},[_vm._v("立即添加")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }