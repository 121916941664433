<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      :rules="rules"
      label-width="100px"
      style="width:100%;"
    >
      <el-form-item label="账号" prop="username" required>
        <el-input v-model="form.username" placeholder="请输入手机号做为登录账号"></el-input>
      </el-form-item>
      <el-form-item label="昵称" prop="username" required>
        <el-input v-model="form.nickname" placeholder="请输入昵称"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="username" required>
        <el-radio v-model="form.gender" :label="0">男</el-radio>
        <el-radio v-model="form.gender" :label="1">女</el-radio>
      </el-form-item>
      <el-form-item label="Email" prop="email">
        <el-input v-model="form.email" placeholder="请输入邮箱"></el-input>
      </el-form-item>

      <el-form-item label="角色" prop="role">
        <el-select multiple v-model="form.role" placeholder="请选择需要绑定角色">
          <el-option
            v-for="(value,key) in roles"
            :key="key"
            :label="value.role_name"
            :value="value.role_id">
            <span style="float: left">{{ value.role_name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ value.role_id }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="标签" prop="tags">
        <el-select multiple v-model="form.tags" placeholder="请选择需要绑定标签">
          <el-option
            v-for="(value,key) in tags"
            :key="key"
            :label="value.name"
            :value="value.tag_id">
            <span style="float: left">{{ value.name }}</span>
            <span style="float: right; font-size: 13px" :style="{'color':value.color}">{{ value.tag_id }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="公司" prop="group_id" v-if="form.group_id === 1">
        <el-select v-model="form.group_id" placeholder="请选择任职公司">
          <el-option
            v-for="(value,key) in groups"
            :key="key"
            :label="value.name"
            :value="value.id">
            <span style="float: left">{{ value.name }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="submit('form')" :plain="true">立即添加</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
  let _this;
  import {getRoles,getTags,addAdmin,getGroups} from "@/api/system.js";
  export default {
    data() {
      return {
        form: {
          username: "",
          nickname: "",
          gender: 0,
          email:'',
          avatar:'',
          role:[],
          tags:[],
          group_id:'',
          department_id:''
        },

        tags:[],
        roles:[],
        groups:[],

        rules: {
          username: [
            {required: true, message: "请输入手机号做为登录账号", trigger: "blur"},
          ],
          nickname: [
            {required: true, message: "请输入昵称", trigger: "change"},
          ],
        },
        
      };
    },

    computed: {},

    mounted() {
      _this = this;
      _this.init();
      this.form.group_id = this.$store.state.user.userData.group_id
    },

    methods: {
      // 初始化数据
      init() {
        getRoles().then(res=>{
          if(res.code === 200){
            this.roles = res.data
          }
        })
        getTags().then(res=>{
          if(res.code === 200){
            this.tags = res.data
          }
        })
        getGroups().then(res=>{
          if(res.code === 200){
            this.groups = res.data
          }
        })
      },
      
      close() {
        _this.$emit("close");
      },
      submit(form) {
        this.$refs[form].validate(valid => {
          if (valid) {
            addAdmin(_this.form).then(res => {
              if (res.code === 200) {
                setTimeout(() => {
                  _this.$emit("success");
                  _this.$emit("close");
                }, 1000);
              }
            })
          } else {
            console.log("error submit!!");
            return false;
          }
        })
      }
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }
</style>
