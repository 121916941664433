<template>
  <section style="height: 100%">
    <div class="container">
      
      <el-main style="width: 100%;box-sizing: border-box;padding-left:20px;">
        <div
          class="worker-tooltips"
          style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start"
        >
          <div class="search-box"><h1>问题列表</h1></div>
          <div class="control-box" style="margin-top: 10px;" v-if="admin_permission.indexOf('look')>-1 || admin_permission.indexOf('add')>-1">
            <div>
              <el-input class="control-box-input" style="width:217px;margin-left:10px;margin-top:10px;" v-model="params.name" placeholder="请输入问题名称" clearable></el-input>
              <el-button icon="el-icon-search" @click="getTableData" style="margin-left:10px;margin-top:10px;">搜索</el-button>
              <el-button icon="el-icon-plus" type="primary" @click="handleOpenAdd" style="margin-top:10px;" v-if="admin_permission.indexOf('add')>-1">添加问题</el-button>
            </div>
          </div>
        </div>
        <el-table
          :data="table"
          style="width: 100%;"
          tooltip-effect="dark"
          empty-text="暂无数据"
          class="table"
        >
          <el-table-column label="问题" prop="question" align="center" width="120"></el-table-column>
          <el-table-column label="答案" prop="answer" align="center"></el-table-column>

          <el-table-column label="操作" width="70" align="center" fixed="right">
            <template slot-scope="scope">
              <div
                style="
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                "
              >
                <el-link style="color: #409eff;" @click="editTable(scope.row.id)" v-if="(scope.row.status !==2 || scope.row.status !==3)">
                  <el-tooltip class="item" effect="dark" content="修改" placement="top-start">
                    <i class="el-icon-edit" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                <el-link style="color: #409eff;" @click="handleDelete(scope.row.id)" v-if="scope.row.menu_id !== 'system_management'">
                  <el-tooltip class="item" effect="dark" content="刪除" placement="top-start">
                  <i class="el-icon-delete" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="width:100%;display:flex;justify-content: flex-end;box-sizing: border-box;padding: 10px;">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :hide-on-single-page="false"
            :small="true"
            :page-size="params.page_count"
            layout="total, prev, pager, next"
            :total="total">
          </el-pagination>
        </div>
      </el-main>
    </div>

    <el-drawer
      title="更改问题信息"
      :visible.sync="editDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <edit-comp v-if="editDrawer" @close="handleClose" @success="handleDrawerSuccess" :id="editID" ref="newForm"></edit-comp>
    </el-drawer>

    <!-- 添加规则 -->
    <el-drawer
      title="添加问题"
      :visible.sync="addDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <add-comp
        @close="handleClose"
        @success="handleDrawerSuccess"
        @clear="clearContent"
        ref="newForm"
        v-if="addDrawer"
      ></add-comp>
    </el-drawer>
  </section>
</template>

<script>
  import { getList, addData,editData,deleteData} from "@/api/question.js";
  import { getGroups} from "@/api/system.js";

  import addComp from "./components/add.vue";
  import editComp from "./components/edit.vue";

  let page_name = 'question'
  export default {
    name: "App",
    components:{addComp,editComp},
    data() {
      return {
        admin_permission:[],
        dialogVisible:false,
        dialogTableVisible:false,
        params: {
          page: 1,
          page_count: 10,
          question:'',
          answer:'',
        },
        groupList:[],
        groupID:'',
        table:[],

        service: [],
        editID: '',
        total: 0,
        outerVisible: false,
        innerVisible: false,
        addDrawer: false,
        editDrawer: false,
        direction: "rtl",
        addTradeParams: {
          name: "",
          parent_id: 0
        },
        visible: false,
        scopeForm:{
          id:'',
          sort: ''
        }
      };
    },

    methods: {
      getTableData() {
        getList(this.params).then(res=>{
          if(res.code === 200){
            this.table = res.data.data
            console.log(this.table)
            this.params.page = res.data.current_page
            this.params.page_count = res.data.per_page
            this.total = res.data.total
          }
        })
      },
      cancel(){
        this.dialogVisible=false
      },
      confirm(){
        let _this = this
        this.dialogVisible=false
        let unitParams ={}
        unitParams.unit=this.unit
        unitParams.unit_price=this.unit_price
        editPrice(this.unitID,unitParams).then((res) => {
          if (res.code === 200) {
            _this.$message({
              message: "修改成功",
              type: "success"
            });
            _this.getTableData()
          }
        });
      },

      handleDetail(e){
        console.log(e)
        this.questionID=e
        this.dialogTableVisible = true
      },
      // 打开添加
      handleOpenAdd() {
        this.addDrawer = true;
      },
      // 关闭添加/修改
      handleClose() {
        this.$confirm("确认关闭？")
          .then((_) => {
            this.addDrawer = false;
            this.editDrawer = false;
          })
          .catch((_) => {});
      },

      handleDelete(id) {
        this.$confirm('删除后无法恢复，请慎重考虑，确认删除数据吗？')
        .then(_ => {
          deleteData(id).then(res=>{
            this.getTableData()
            this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success'
            });
          })
          this.getTableData()
        })
      },
      editTable(id){
        this.editID = id
        this.editDrawer = true
      },
      // 添加/修改成功
      handleDrawerSuccess() {
        this.getTableData()
        this.addDrawer = false;
        this.editDrawer = false;
        // this.searchSet()
        this.$refs.newForm.$refs.form.resetFields();
      },
      // 清空子组件
      clearContent() {
        this.$refs.newForm.$refs.form.resetFields();
      },
      // 分页
      prevpage() {
        this.params.page -= 1;
        this.getTableData();
      },
      nextpage() {
        this.params.page += 1;
        this.getTableData();
      },
      handleCurrentChange(val) {
        this.params.page = val;
        this.getTableData();
      },
    },
    mounted() {
      this.admin_permission = this.$store.state.permission.permissionArr[page_name]
      this.getTableData();
    }
  };
</script>

<style scoped lang="scss">
  @import "@/static/default";

  .container {
    width: 100%;
    min-height: 100%;
    background-color: #fff !important;
    padding: 20px 32px;
    padding-bottom:0;
    display: inline-flex;

    .table {
      margin-top: 24px !important;
    }

    .toolbar {
      background: #fff;
      width: 100%;
      padding: 16px 32px 26px 0;
    }
  }

  .control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    &-input{
      width: 150px;
    }
  }
  .el-main {
    padding: 0;
  }

  .demo-table-expand {
    font-size: 0;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>
<style>
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 10px 0;
    padding-right: 8px;
  }
  .el-tree-node__expand-icon.is-leaf{
    color: #c0c4cc;
  }
</style>
