<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      :rules="rules"
      label-width="100px"
      style="width:100%;"
    >
      <el-form-item label="版本" prop="version" required>
        <el-input v-model="form.version" placeholder="请输入版本号"></el-input>
      </el-form-item>

      <el-form-item label="链接" prop="src" required>
        <el-input v-model="form.src" placeholder="请输入类型"></el-input>
      </el-form-item>

      <el-form-item label="类型" prop="type" >
        <el-select v-model="form.type" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="submit('form')" :plain="true">立即修改</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
  let _this;
  import {getByID,updateVersion} from "@/api/version.js";
  let form = {
          versiom: "",
          type: "",
          src: '',
        }
  export default {
    props: {
      id: Number
    },
    data() {
      return {
        form: form,
        value: '',
        options: [{
          value: 0,
          label: '整包'
        }, {
          value: 1,
          label: 'wgt包'
        }],
        rules: {
          version: [
            {required: true, message: "请输入版本号", trigger: "blur"},
          ],
          type: [
            {required: true, message: "请输入类型", trigger: "blur"},
          ],
          scr: [
            {required: true, message: "请选择链接", trigger: "change"},
          ]
        },
      };
    },

    computed: {},

    mounted() {
      _this = this;
      _this.init();
    },

    methods: {
      // 初始化数据
      init() {
        getByID(this.id).then(res=>{
          if (res.code === 200){
            this.form = res.data
            console.log(this.form)
          }
        })
      },
     
      close() {
        _this.$emit("close");
      },
      submit(form) {
        this.$refs[form].validate(valid => {
          if (valid) {
            updateVersion(this.id,_this.form).then(res => { 
              if (res.code === 200) {
                _this.$message({
                  message: "更新成功",
                  type: "success"
                });
                  _this.$emit("success");
              }
            })
              this.form = form
          } else {
            console.log("error submit!!");
            return false;
          }
        })
      }
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }

  .icon-dialog{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .icon-dialog-list{
      box-sizing: border-box;
      padding: 20px 10px;
      width: 16.66%;
      font-size: 32px;
      text-align: center;
      border:1px solid #dbdbdb;
    }
    .icon-dialog-list:hover{
      color:#409eff;
      border-color:#409eff;
    }
  }
  .icon-name{
    margin-top:10px;
    font-size: 12px;
  }
  
</style>
<style>
  .el-dialog__body{
    max-height: 60vh;
    overflow-y: auto;
  }
</style>
