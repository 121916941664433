import axios, {
  ResponseData
} from './base.ts'
import {
  AxiosPromise
} from 'axios'
const url = ``

export function getList(data = {}) {
  return axios.request({
    url: `${url}/banners`,
    method: "get",
    params: data,
  })
}

export function getListSelect(data = {}) {
  return axios.request({
    url: `${url}/banners/select`,
    method: "get",
    params: data,
  })
}
export function getDataById(id,params={}){
  return axios.request({
    url: `${url}/banner/${id}`,
    method: "get",
    params,
  })
}

export function deleteData(id,data) {
  return axios.request({
    url: `${url}/banner/${id}`,
    method: "delete",
    params:data,
  })
}
export function addData(data) {
  return axios.request({
    url: `${url}/banner`,
    method: "post",
    data: data,
  })
}
export function editData(id, data) {
  return axios.request({
    url: `${url}/banner/${id}`,
    method: "put",
    data: data,
  })
}