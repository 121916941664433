import axios, {
  ResponseData
} from './base.ts'
import {
  AxiosPromise
} from 'axios'
const url = ``

export function getList(data = {}) {
  return axios.request({
    url: `${url}/stores`,
    method: "get",
    params: data,
  })
}
export function getTypeList(data = {}) {
  return axios.request({
    url: `${url}/store/types/select`,
    method: "get",
    params: data,
  })
}
export function searchTypeList(data = {}) {
  return axios.request({
    url: `${url}/store/types`,
    method: "get",
    params: data,
  })
}
export function getListById(id,data = {}) {
  return axios.request({
    url: `${url}/store/${id}`,
    method: "get",
    params: data,
  })
}
export function getTypeById(id,data = {}) {
  return axios.request({
    url: `${url}/store/type/${id}`,
    method: "get",
    params: data,
  })
}
export function getListSelect(data = {}) {
  return axios.request({
    url: `${url}/stores/select`,
    method: "get",
    params: data,
  })
}
export function getTypeSelect(data = {}) {
  return axios.request({
    url: `${url}/store/types/select`,
    method: "get",
    params: data,
  })
}
export function deleteData(id,data) {
  return axios.request({
    url: `${url}/store/${id}`,
    method: "delete",
    params:data,
  })
}
export function deleteType(id,data) {
  return axios.request({
    url: `${url}/store/type/${id}`,
    method: "delete",
    params:data,
  })
}
export function addData(data) {
  return axios.request({
    url: `${url}/store`,
    method: "post",
    data: data,
  })
}
export function addType(data) {
  return axios.request({
    url: `${url}/store/type`,
    method: "post",
    data: data,
  })
}
export function editData(id, data) {
  return axios.request({
    url: `${url}/store/${id}`,
    method: "put",
    data: data,
  })
}
export function editType(id, data) {
  return axios.request({
    url: `${url}/store/type/${id}`,
    method: "put",
    data: data,
  })
}
export function editPrice(id, data) {
  return axios.request({
    url: `${url}/store/set/${id}`,
    method: "put",
    data: data,
  })
}
export function editPercent(id, data) {
  return axios.request({
    url: `${url}/store/devided/${id}`,
    method: "put",
    data: data,
  })
}