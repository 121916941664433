<template>
    <el-descriptions direction="vertical" :column="4" border>
        <el-descriptions-item label="商户名称" :span="4">{{storeData.name}}</el-descriptions-item>
       
        <el-descriptions-item label="主负责人">{{storeData.realname}}</el-descriptions-item>
        <el-descriptions-item label="联系方式">{{storeData.phone}}</el-descriptions-item>
        <el-descriptions-item label="商户类型">
            <el-tag type="success" v-if="storeData.conn===0">wifi</el-tag>
            <el-tag type="success" v-else-if="storeData.conn===1">Auto</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="商户大小">
            <el-tag v-if="storeData.store_size===0">普通</el-tag>
            <el-tag v-else-if="storeData.store_size===1">中型</el-tag>
            <el-tag v-else-if="storeData.store_size===2">大型</el-tag>
            <el-tag v-else-if="storeData.store_size===3">超大型</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="铺设类型">
            <el-tag v-if="storeData.lay_type===0">独家</el-tag>
            <el-tag v-else-if="storeData.lay_type===1">共存</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="商户省份">{{storeData.province_name}}</el-descriptions-item>
        <el-descriptions-item label="商户城市">{{storeData.city_name}}</el-descriptions-item>
        
        <el-descriptions-item label="地址" :span="2">{{storeData.address}}</el-descriptions-item>

        <el-descriptions-item label="主设备数">{{storeData.csq}}</el-descriptions-item>
        <el-descriptions-item label="总设备数">{{storeData.rsrp}}</el-descriptions-item>
        <el-descriptions-item label="单位">{{storeData.unit}}</el-descriptions-item>
        <el-descriptions-item label="单价">{{storeData.uni_price}}</el-descriptions-item>
        <el-descriptions-item label="信用代码">{{storeData.credit_code}}</el-descriptions-item>
        <el-descriptions-item label="营业时间">{{storeData.business_start_time}}至{{storeData.business_end_time}}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{storeData.updated_at}}</el-descriptions-item>
    </el-descriptions>
  </template>
  
  <script>
    let _this;
    import {getListById} from "@/api/store.js";
    export default {
        props:['id'],
        data(){
            return{
                storeData:[],
            }
        },
        mounted(){
            this.init()
        },
        methods:{
            // 初始化数据
            init() {
                getListById(this.id).then(res=>{
                    if (res.code === 200){
                        this.storeData = res.data
                        console.log(this.form)
                    }
                })
            },
        }
    };
  </script>
  <style lang="scss" scoped>
  </style>
  