<template>
  <section style="height: 100%">
    <div class="container">
      <el-main style="width: 100%;box-sizing: border-box;padding-left:20px;">
        <div
          class="worker-tooltips"
          style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start"
        >
          <div class="search-box"><h1>商户列表</h1></div>
          <div class="control-box" style="margin-top: 10px;" v-if="admin_permission.indexOf('look')>-1 || admin_permission.indexOf('add')>-1">
            <el-input class="control-box-input" v-model="params.serial_number" style="width:200px;margin-left:10px;margin-top: 10px;" placeholder="请输入搜索设备号" clearable></el-input>
            <el-select v-model="params.store_id" placeholder="请选择或输入商户" style="margin-left:10px;margin-top: 10px;" clearable filterable>
              <el-option
                v-for="item in storeList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="params.type" placeholder="请选择类型" clearable style="margin-left:10px;width:150px;margin-top: 10px;">
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="params.group_id" placeholder="请选择公司" filterable clearable style="margin-left:10px;margin-top: 10px;" v-if="groupID===1">
              <el-option
                v-for="item in groupList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="params.is_init" placeholder="是否初始化" clearable style="margin-left:10px;width:150px;margin-top: 10px;">
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-button icon="el-icon-search" @click="getTableData" style="margin-left:10px;margin-top: 10px;">搜索</el-button>
            <el-button icon="el-icon-plus" type="primary" @click="handleOpenAdd" style="margin-left:10px;margin-top: 10px;" v-if="admin_permission.indexOf('add')>-1">添加设备</el-button>
          </div>
        </div>
      <!-- 轮播列表 -->
        <el-table :data="tableData" style="width: 100%" class="table" :height="height" v-if="height>0">
          <!-- 数据展示区 -->
          <el-table-column label="ID" prop="id" align="center" width="60px">
          </el-table-column>
          <el-table-column label="设备号" prop="serial_number" align="left" width="160px">
          </el-table-column>
          <el-table-column label="类型" prop="type" align="left" width="80px">
            <template slot-scope="scope">
              <el-tag size="small" v-if="(scope.row.type === 0)">立式</el-tag>
              <el-tag size="small" type="success" v-if="(scope.row.type === 1)">小型</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="充电宝个数" prop="battery_count" align="left" width="100px">
          </el-table-column>
          <el-table-column label="屏幕绑定" prop="permission" align="left" width="200px">
            <template slot-scope="scope">
              <span v-if="!scope.row.app">未激活终端APP</span>
              <el-tag size="small" v-else  effect="plain">终端OAID:{{scope.row.app}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="绑定商家" prop="comment" align="left" width="200px">
            <template slot-scope="scope">
              <span v-if="(scope.row.store_id === 0)">未绑定商户</span>
              <el-popover trigger="hover" placement="top"  v-else>
                <el-descriptions class="margin-top" :title="scope.row.store.name" :column="1" :border="true">
                  <template slot="extra">
                    <el-button type="primary" size="small">查看详情</el-button>
                  </template>
                  <el-descriptions-item>
                    <template slot="label" :span="2">
                      <i class="el-icon-mobile-phone"></i>
                      联系号码
                    </template>
                    {{ scope.row.store.phone }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label" :span="2">
                      <i class="el-icon-office-building"></i>
                      联系地址
                    </template>
                    {{ scope.row.store.province_name }} {{ scope.row.store.city_name }} {{ scope.row.store.area_name }} {{ scope.row.store.address }}
                  </el-descriptions-item>
                </el-descriptions>
                <div slot="reference" class="name-wrapper" >
                  <el-tag size="medium" effect="plain">{{ scope.row.store.name }}</el-tag>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="所属省份" prop="province" align="left" width="160px">
            <template slot-scope="scope">
              {{scope.row.store.province_name}}
            </template>
          </el-table-column>
          <el-table-column label="所属城市" prop="city" align="left" width="160px">
            <template slot-scope="scope">
              {{scope.row.store.city_name}}
            </template>
          </el-table-column>
          <el-table-column label="所属公司" prop="company" align="left" width="160px">
          </el-table-column>
          <el-table-column label="设备号" prop="serial_number" align="left">
          </el-table-column>
          <el-table-column label="操作" fixed="right" align="center" width="100px">
            <template slot-scope="scope">
              <div
                style="
                    width: 100%;
                    display: inline-flex;
                    justify-content: space-between;
                  "
              >
                <el-link style="color: #409eff;" @click="handleDetail(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="查看詳情" placement="top-start">
                    <i class="el-icon-view" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                <el-link style="color: #409eff;" @click="handleEdit(scope.row)">
                  <el-tooltip class="item" effect="dark" content="修改" placement="top-start">
                    <i class="el-icon-edit" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                <el-link style="color: #409eff;" @click="handleDelete(scope.row.id)" v-if="scope.row.menu_id !== 'system_management'">
                  <el-tooltip class="item" effect="dark" content="刪除" placement="top-start">
                  <i class="el-icon-delete" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
              
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="width:100%;display:flex;justify-content: flex-end;box-sizing: border-box;padding: 10px;">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :hide-on-single-page="false"
            :small="true"
            :page-size="params.page_count"
            layout="total, prev, pager, next"
            :total="total">
          </el-pagination>
        </div>
      </el-main>

      
      <!-- 查看详情信息 -->
      <el-dialog title="设备详情" v-if="dialogTableVisible" :visible.sync="dialogTableVisible">
        <device-list :id="deviceID"></device-list>
      </el-dialog>
      <!-- 添加活动信息 -->
      <el-drawer
        title="添加设备"
        :visible.sync="addDrawer"
        :direction="direction"
        :before-close="handleClose"
        
      >
        <add
          @close="handleClose"
          @success="handleDrawerSuccess"
          ref="newForm"
        ></add>
      </el-drawer>

      <!-- 修改活动信息 -->
      <el-drawer
        title="更改设备"
        :visible.sync="editDrawer"
        :direction="direction"
        :before-close="handleClose"
      >
        <edit
          ref="newForm"
          v-if="editDrawer"
          @close="handleClose"
          @success="handleDrawerSuccess"
          :id="editParam.id"
        ></edit>
      </el-drawer>
    </div>
  </section>
</template>

<script>
import {getList,deleteData,updateData} from '@/api/device.js'
import {searchGroups} from "@/api/system.js";
import {getListSelect} from "@/api/store.js";
import {QueryTheInventory} from '@/api/websocket/index'
import ToolsBox from "@/components/tool/Index.vue";
import deviceList from "@/views/components/deviceList.vue";
import edit from "./components/edit.vue";
import add from "./components/add.vue";

let page_name = 'device_management'
export default {
  name: "App",
  components: { ToolsBox,edit,add,deviceList },
  data() {
    return {
      admin_permission:[],
      height:0,
      toolsConfig: [],
      dialogTableVisible:false,
      deviceID:'',
      groupList:[],
      groupID:'',
      typeList:[
        {
          value: 0,
          label: '立式'
        }, {
          value: 1,
          label: '座机'
        }
      ],
      statusList:[
        {
          value: 0,
          label: '否'
        }, {
          value: 1,
          label: '是'
        },
      ],
      storeList:[],
      //搜索条件
      params: {
        page:1,
        page_count: 10,
        type:'',//类型
        group_id:'',
        status:"",//是否初始化
        store_name:"",
        name:"",
        is_init:""
      },
      // 查询数据
      tableData: [
        
      ],
      total: 0,

      // 添加组件传参
      addDrawer: false,
      addParam:{

      },

      // 更新组件传参
      editDrawer: false,
      editParam: {
        id:0
      },

      direction: "rtl",
    };
  },
  websocketonmessage(e){ //数据接收
      const redata = JSON.parse(e.data);
      if(redata.code === 200){
        console.log(redata.data)
        if(redata.event === 'new_order'){
          console.log(redata.data)
          this.$notify.info({
            title: '有新的订单',
            dangerouslyUseHTMLString: true,
            message: '<div style="width: 100%"><strong>'+redata.data.service_name+'</strong><br/><span>'+redata.data.province_name+' '+redata.data.city_name+' '+redata.data.area_name+' '+redata.data.address+'</span></div>'
          });
        }
      }
    },
  mounted() {
    this.admin_permission = this.$store.state.permission.permissionArr[page_name]
    this.getTableData();
    this.groupID = this.$store.state.user.userData.group_id
    getListSelect().then(res=>{
      if(res.code === 200){
        console.log(res.data)
        this.storeList = res.data
      }
    })
    searchGroups().then(res=>{
      if(res.code === 200){
        console.log(res.data.data)
        this.groupList = res.data.data
      }
    })
    if(document.body.clientHeight&&document.documentElement.clientHeight)
    {
      var clientHeight = (document.body.clientHeight<document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
    }
    else
    {
      var clientHeight = (document.body.clientHeight>document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
    }
    console.log(clientHeight)
    this.height = clientHeight-82-60-40-20-20-40
    // this.socketApi.getSock(this.getConfigResult);
  },
  methods: {
    getTableData(){
      getList(this.params).then(res=>{
        if (res.code === 200){
          console.log(res.data.data)
          this.tableData = res.data.data
          this.params.page = res.data.current_page
          this.params.page_count = res.data.per_page
          this.total = res.data.total
        }
      })
    },
    handleDetail(e){
      console.log(e)
      this.deviceID=e
      this.dialogTableVisible = true
    },
    handleCurrentChange(val){
      console.log(val)
    },

    // 删除轮播
    handleDelete(id) {
      this.$confirm('删除后无法恢复，请慎重考虑，确认删除数据吗？')
        .then(_ => {
          deleteData(id).then(res=>{
            this.getTableData()
            this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success'
            });
          })
        })
        .catch(_ => {});
    },
    getConfigResult(res){
      console.log(res.data)
    },

    handleOpenAdd() {
      this.addDrawer = true;
    },
    handleEdit(row) {
      this.editParam = {
        id : row.id,
      }
      this.editDrawer = true
    },
    handleClose() {
      this.$confirm("确认关闭？")
        .then(() => {
          this.addDrawer = false;
          this.editDrawer = false;
          this.$refs.newForm.$refs.form.resetFields(); //清空子组件的内容
        })
        .catch(() => {});
    },
    handleDrawerSuccess(){
      this.addDrawer = false;
      this.editDrawer = false;
      this.getTableData();
      this.$refs.newForm.$refs.form.resetFields();//清空子组件的内容
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/static/default";
.banner {
  width: 100%;
  background-color: white;
  padding: 10px 50px;
  // padding-left: 50px;
}
.container {
    width: 100%;
    min-height: 100%;
    background-color: #fff !important;
    padding: 20px 32px;
    padding-bottom:0;
    display: inline-flex;
  .table {
    margin-top: 24px !important;
  }
  .toolbar {
    background: #fff;
    width: calc(100% + 64px);
    padding: 16px 32px 26px 0;
    margin-left: -32px;
  }
  .control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    &-input{
      width: 150px;
    }
  }
  .el-main {
    padding: 0;
  }

}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
