<template>
    <el-descriptions direction="vertical" :column="4" border>
        <el-descriptions-item label="设备号" :span="4">{{deviceData.serial_number}}</el-descriptions-item>
       
        <el-descriptions-item label="公司名称">
            <span v-if="deviceData.group_id>0">{{deviceData.group_data.name}}</span>
            <span v-else>未绑定公司</span>
        </el-descriptions-item>
        <el-descriptions-item label="商户名称">
            <span v-if="deviceData.store_id>0">{{deviceData.store_data.name}}</span>
            <span v-else>未绑定商户</span>
        </el-descriptions-item>
        <el-descriptions-item label="剩余充电宝数量">{{deviceData.battery_count}}</el-descriptions-item>
        <el-descriptions-item label="充电宝总数量">{{deviceData.battery_total}}</el-descriptions-item>
        
        <el-descriptions-item label="网络模块">
            <el-tag v-if="deviceData.conn===1">仅支持cell</el-tag>
            <el-tag v-else-if="deviceData.conn===2">仅支持wifi</el-tag>
            <el-tag v-else-if="deviceData.conn===3">cell+wifi</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="网络连接模式">
            <el-tag type="success" v-if="deviceData.conn===0">wifi</el-tag>
            <el-tag type="success" v-else-if="deviceData.conn===1">Auto</el-tag>
            <el-tag type="success" v-else-if="deviceData.conn===2">2G</el-tag>
            <el-tag type="success" v-else-if="deviceData.conn===3">3G</el-tag>
            <el-tag type="success" v-else-if="deviceData.conn===4">4G</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="SIM卡号" :span="2">{{deviceData.iccid}}</el-descriptions-item>

        <el-descriptions-item label="信号强度">{{deviceData.csq}}</el-descriptions-item>
        <el-descriptions-item label="信号接收功率">{{deviceData.rsrp}}</el-descriptions-item>
        <el-descriptions-item label="信噪比">{{deviceData.sinr}}</el-descriptions-item>
        <el-descriptions-item label="WIFI 信号强度">{{deviceData.wifi}}</el-descriptions-item>
        <el-descriptions-item label="设备是否初始化">
            <el-tag v-if="deviceData.app">已初始化</el-tag>
            <el-tag v-else type="info">未初始化</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="通信板软件版本号">{{deviceData.commsoftver}}</el-descriptions-item>
        <el-descriptions-item label="通信板硬件版本号">{{deviceData.commhardver}}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{deviceData.created_at}}</el-descriptions-item>
    </el-descriptions>
  </template>
  
  <script>
    let _this;
    import {getDataById} from "@/api/device.js";
    export default {
        props:['id'],
        data(){
            return{
                deviceData:[],
            }
        },
        mounted(){
            this.init()
        },
        methods:{
            // 初始化数据
            init() {
                getDataById(this.id).then(res=>{
                    if (res.code === 200){
                        this.deviceData = res.data
                        console.log(this.form)
                    }
                })
            },
        }
    };
  </script>
  <style lang="scss" scoped>
  </style>
  