<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      :rules="rules"
      label-width="100px"
      style="width:100%;"
    >
      <el-form-item label="角色标识" prop="role_id" required>
        <el-input v-model="form.role_id" placeholder="请输入角色标识,不可重复"></el-input>
      </el-form-item>

      <el-form-item label="角色名称" prop="role_name" required>
        <el-input v-model="form.role_name" placeholder="请输入角色名称"></el-input>
      </el-form-item>

      <el-form-item label="角色权限" prop="permission">
        <el-select v-model="permission_selected" multiple placeholder="请选择">
          <el-option
            v-for="(value,key) in permission"
            :key="value.id"
            :label="value.permission_name"
            :value="value.permission_id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="备注" prop="icon" >
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入备注"
          v-model="form.comment">
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="submit('form')" :plain="true">立即添加</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
  let _this;
  import {addRole,getPermissions} from "@/api/system.js";
  let form = {
          role_id: "",
          role_name: "",
          comment: '',
          permission:[]
        }
  export default {
    
    data() {
      return {
        form: form,

        rules: {
          role_id: [
            {required: true, message: "请输入角色标识,不可重复", trigger: "blur"},
          ],
          role_name: [
            {required: true, message: "请输入角色名称", trigger: "blur"},
          ]
        },

        permission:[],
        permission_selected:[]
      };
    },

    computed: {},

    mounted() {
      _this = this;
      _this.init();
    },

    methods: {
      // 初始化数据
      init() {
        getPermissions().then(res=>{
          if(res.code === 200){
            this.permission = res.data
          }
        })
      },
     
      close() {
        _this.$emit("close");
      },
      submit(form) {
        this.$refs[form].validate(valid => {
          if (valid) {
            addRole(_this.form).then(res => { 
              if (res.code === 200) {
                _this.$message({
                  message: "添加成功",
                  type: "success"
                });
                this.form = form
                setTimeout(() => {
                  _this.$emit("success");
                }, 1000);
              }
            })
          } else {
            return false;
          }
        })
      }
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }

  .icon-dialog{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .icon-dialog-list{
      box-sizing: border-box;
      padding: 20px 10px;
      width: 16.66%;
      font-size: 32px;
      text-align: center;
      border:1px solid #dbdbdb;
    }
    .icon-dialog-list:hover{
      color:#409eff;
      border-color:#409eff;
    }
  }
  .icon-name{
    margin-top:10px;
    font-size: 12px;
  }
  
</style>
<style>
  .el-dialog__body{
    max-height: 60vh;
    overflow-y: auto;
  }
</style>
