<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      :rules="rules"
      label-width="100px"
      style="width:100%;"
    >
      <el-form-item label="标签标识" prop="tag_id" required>
        <el-input v-model="form.tag_id" placeholder="请输入标签标识,不可重复"></el-input>
      </el-form-item>

      <el-form-item label="标签名" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入标签名称"></el-input>
      </el-form-item>

      <el-form-item label="标签颜色" prop="color">
        <el-color-picker
          v-model="form.color"
          show-alpha
          :predefine="predefineColors">
        </el-color-picker>
      </el-form-item>

      <el-form-item label="标签描述" prop="description">
        <el-input v-model="form.description" placeholder="请输入标签描述"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="submit('form')" :plain="true">立即添加</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
  let _this;
  import {addTag,getPermissions} from "@/api/system.js";
  let form = {
          tag_id: "",
          name: "",
          description: '',
          color:"",
          
        }
  export default {
    
    data() {
      return {
        form: form,

        rules: {
          tag_id: [
            {required: true, message: "请输入标签标识,不可重复", trigger: "blur"},
          ],
          name: [
            {required: true, message: "请输入标签名称", trigger: "blur"},
          ]
        },

        predefineColors:[
          '#ff4500',
          '#ff8c00',
          '#ffd700',
          '#90ee90',
          '#00ced1',
          '#1e90ff',
          '#c71585',
          'rgba(255, 69, 0, 0.68)',
          'rgb(255, 120, 0)',
          'hsv(51, 100, 98)',
          'hsva(120, 40, 94, 0.5)',
          'hsl(181, 100%, 37%)',
          'hsla(209, 100%, 56%, 0.73)',
          '#c7158577'
        ]
      };
    },

    computed: {},

    mounted() {
      _this = this;
      _this.init();
    },

    methods: {
      // 初始化数据
      init() {
        // getPermissions().then(res=>{
        //   if(res.code === 200){
        //     this.permission = res.data
        //   }
        // })
      },
     
      close() {
        _this.$emit("close");
      },
      submit(form) {
        this.$refs[form].validate(valid => {
          if (valid) {
            addTag(_this.form).then(res => { 
              if (res.code === 200) {
                _this.$message({
                  message: "添加成功",
                  type: "success"
                });
                this.form = form
                setTimeout(() => {
                  _this.$emit("success");
                }, 1000);
              }
            })
          } else {
            return false;
          }
        })
      }
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }

  .icon-dialog{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .icon-dialog-list{
      box-sizing: border-box;
      padding: 20px 10px;
      width: 16.66%;
      font-size: 32px;
      text-align: center;
      border:1px solid #dbdbdb;
    }
    .icon-dialog-list:hover{
      color:#409eff;
      border-color:#409eff;
    }
  }
  .icon-name{
    margin-top:10px;
    font-size: 12px;
  }
  
</style>
<style>
  .el-dialog__body{
    max-height: 60vh;
    overflow-y: auto;
  }
</style>
