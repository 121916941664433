<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      :rules="rules"
      label-width="100px"
      style="width:100%;"
    >
      <el-form-item label="设备号" prop="serial_number" required>
        <el-input v-model="form.serial_number" placeholder="请输入设备号,不可重复"></el-input>
      </el-form-item>

      <el-form-item label="类型" prop="type" required>
        <el-radio-group v-model="form.type" size="small">
          <el-radio :label="0" border>立式</el-radio>
          <el-radio :label="1" border>座机</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="商家" prop="store_id">
        <el-select v-model="form.store_id" filterable placeholder="请选择商家" clearable @clear="handleStoreClear">
          <el-option label="请选择商家" :value="0">请选择商家</el-option>
          <el-option
            v-for="(value,key) in stores"
            :key="value.id"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="充电宝个数" prop="battery_count" >
        <el-input-number v-model="form.battery_count" :min="0" :max="100" label="请输入充电宝个数"></el-input-number>
      </el-form-item>

      <el-form-item label="快递号" prop="express" >
        <el-input v-model="form.express" placeholder="请输入快递号"></el-input>
      </el-form-item>

      <el-form-item label="快递状态" prop="express" >
        

        <el-radio-group v-model="form.express_status" size="small">
          <el-radio :label="0">未发送</el-radio>
          <el-radio :label="1">已发送</el-radio>
          <el-radio :label="2">已收获</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="submit('form')" :plain="true">立即添加</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
  let _this;
  import {addData} from "@/api/device.js";
  import {getListSelect} from "@/api/store.js"
  let form = {
    serial_number: "",
    type: 0,
    store_id: 0,
    agent_id:0,
    battery_count:0,
    express:'',
    express_status:0
  }
  export default {
    
    data() {
      return {
        form: form,

        rules: {
          serial_number: [
            {required: true, message: "SN不能为空,不可重复", trigger: "blur"},
          ],
          type: [
            {required: true, message: "类型不能为空", trigger: "blur"},
          ]
        },

        stores:[],
        stores_select:[],

        agents:[],
        agents_select:[]
      };
    },

    computed: {},

    mounted() {
      _this = this;
      _this.init();
    },

    methods: {
      // 初始化数据
      init() {
        getListSelect().then(res=>{
          if(res.code === 200){
            this.stores = res.data
          }
        })
      },
     
      close() {
        _this.$emit("close");
      },
      handleStoreClear(){
        this.form.store_id = 0
      },
      submit(form) {
        this.$refs[form].validate(valid => {
          if (valid) {
            addData(_this.form).then(res => { 
              if (res.code === 200) {
                _this.$message({
                  message: "添加成功",
                  type: "success"
                });
                  _this.$emit("success");
              }
                this.form = form
            })
          } else {
            return false;
          }
        })
      }
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }

  .icon-dialog{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .icon-dialog-list{
      box-sizing: border-box;
      padding: 20px 10px;
      width: 16.66%;
      font-size: 32px;
      text-align: center;
      border:1px solid #dbdbdb;
    }
    .icon-dialog-list:hover{
      color:#409eff;
      border-color:#409eff;
    }
  }
  .icon-name{
    margin-top:10px;
    font-size: 12px;
  }
  
</style>
<style>
  .el-dialog__body{
    max-height: 60vh;
    overflow-y: auto;
  }
</style>
