import axios, {ResponseData} from './base.ts'
import {AxiosPromise} from 'axios'
const path=''

export function getVersion(data = {}) {
  return axios.request({
    url: `/versions`,
    method: "get",
    params: data,
  })
}
export function getByID(id, params={}){
  return axios.request({
    url:`/version/${id}`,
    method: "get",
    params,
  })
}
export function deleteVersion(id,data) {
  return axios.request({
    url: `/version/${id}`,
    method: "delete",
    params:data,
  })
}
export function addData(data) {
  return axios.request({
    url: `/version`,
    method: "post",
    data: data,
  })
}
export function updateVersion(id, data) {
  return axios.request({
    url: `/version/${id}`,
    method: "put",
    data: data,
  })
}